.mainContainer {
	width: 100%;
	justify-content: space-between;
	/* align-items: center; */
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
	height: 50px;
	position: relative;
}
.mainContainerNoData {
	width: 100%;
	justify-content: space-between;
	/* align-items: center; */
	display: flex;
	pointer-events: none;
	margin-top: 10px;
	margin-bottom: 10px;
}

.clipLoader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btnContent {
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-overflow: ellipsis;
}

.dropDownContainer {
	display: flex;
	flex-direction: column;
}

.selected {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 15px;
}

.dropDownBtn {
	border: 0;
	border-radius: 5px;
	background: #f4f4f4;
	width: 180px;
	padding: 6px 8px;
	text-align: center;
	font-family: "Mulish";
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;
	position: relative;
	margin-right: 5px;
	height: 100%;
}
.ulContainer {
	background-color: #fff;
	border: 1px solid #f4f4f4;
	display: flex;
	flex-direction: column;
	max-width: 180px;
	width: 180px;
	padding: 0;
	max-height: 100px;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	top: 100%;
	list-style-type: none;
	left: 0;
	margin-top: 2px;
}
.option:hover {
	background-color: #f4f4f4;
	border-radius: 10px;
	cursor: pointer;
}

.option {
	text-overflow: ellipsis;
	border-radius: 6px;
	padding: 10px;
	font-size: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}
.selectedOption {
	text-overflow: ellipsis;
	background-color: #f4f4f4;
	border-radius: 6px;
	padding: 10px;
	font-size: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}
.input {
	flex: 7;
	border-radius: 5px;
	border: 0.5px solid #000;
	padding: 5px;
}

.btnContainer {
	width: 100%;
	flex: 2;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.btn {
	align-items: center;
	background-color: #000;
	border: none;
	border-radius: 6px;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
	cursor: pointer;
	display: flex;
	font-family: Mulish;
	font-size: 16px;
	justify-content: center;
	width: 100%;
	padding: 10px;
	margin-left: 5px;
}
