.collapseSection {
	align-items: center;
	background-color: #f4f4f4;
	border-radius: 10px;
	gap: 10px;
	padding: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	transition: 0.4s all;
}

.mainTitleContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	background-color: #f4f4f4;
	padding: 4px;
}
.mainTitle {
	font-weight: 800;
}

.mainBodyContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}
.container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;
	grid-column-gap: 25px;
	margin-bottom: 10px;
}
.containerInventory{
	width: 100%;
	
	margin-bottom: 10px;
}
.mainContainer {
	max-width: 1200px;
	margin: auto;
	font-family: "Mulish";
	width: 100%;
	padding: 0 20px 20px;
}

.clipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}
.btnClipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 100vh;
	width: 100%; */
}

.headerContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 15px;
	margin-bottom: 15px;
}

.headerOptions {
	align-self: center;
	margin-left: auto;

	.inlineInput {
		margin-bottom: 0px;
	}
}

.inlineInput {
	display: flex;
	flex-direction: row;
	gap: 15px;
	margin-bottom: .5rem;
	align-items: center;

	label {
		margin: 0px;
	}

	select {
		height: 30px;
		background-color: #f4f4f4;
		border-radius: 5px;
		border: none;
	}
}

.titleContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.pageTitle {
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
}

.bodyTitle {
	font-weight: 700;
}

.bodyContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}
.modal-header-custom {
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.modalInput::-webkit-inner-spin-button,
.modalInput::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.modalInput:focus {
	outline: none;
}
.modalInput{
  width: 100%;
  height: 40px;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 5px;
	border: none;
}
.modalButtonsContainer{
	display: flex;
	flex-direction: row;
	gap: 1rem;
	justify-content: center;
	width: 100%;
}
.modalInput[readonly] {
  background-color: #f5f5f5; /* Light gray background */
  color: #a9a9a9; /* Dim text color */
  border: 1px solid #dcdcdc; /* Light border */
  cursor: not-allowed; /* Change cursor to indicate non-editable */
}
.bodyContainerHours {
	width: 100%;
	display: flex;
	flex-direction: row;
	/* padding-bottom: 10px; */
}
.contentContainer {
	display: flex;
	flex-direction: column;
	background-color: #ffff;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	border-radius: 5px;
	height: 100%;
}
.bodyText{
	width: 25%;
	display: flex;
	/* justify-content: center; */
}
.contentContainerTitle{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #ffff;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	border-radius: 5px;
	width: 100%;
}
.addBtn,
.editBtn,
.prefillBtn,
.getSkuDetailsBtn {
	min-width: 100px;
	min-height: 45px;
	border-radius: 5px;
	outline: none;
}
.addBtn,
.prefillBtn{
	background-color: #000;
	color: #fff;
}
.editBtn,
.getSkuDetailsBtn {
	background-color: transparent;
	color: black;
}
.getSkuDetailsBtnContainer {
	align-items: center;
	display: flex;
	justify-content: center;
	padding-top: 1rem;
	width: 100%;
}
.getSkuDetailsBtn {
	display: inline-block;
	margin-inline: auto;
}

.bodyTitleInventory{
	width: 25%;
	display: flex;
	/* justify-content: center; */
	font-weight: 700;
}
.contentContainerInventory{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	background-color: #ffff;
	padding-left: 5px;
	padding-top: 5px;
	/* padding-bottom: 5px; */
	padding-right: 5px;
	border-radius: 5px;
	width: 100%;
}
.bodyTitleNoInventory{
	width: 100%;
	display: flex;
	justify-content: center;
	font-weight: 700;
}
.containerButtonInventory{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
}
.hoursContainer{display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	background-color: #f4f4f4;
	border-radius: 5px;
	padding: 5px;
	margin: 5px 0px;
	width: 100%;}
.contentContainerNoInventory{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	/* background-color: #ffff; */
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	border-radius: 5px;
	width: 100%;
}
.editableField {
	display: flex;
	align-items: center;
}
.editField {
	display: flex;
	align-items: center;
}
.input {
	background-color: #f4f4f4;
	border: none;
	border-radius: 5px;
	font-family: Mulish;
	height: 40px;
	width: 100%;
	padding: 5px;
}

.actionBtnsContainer{
	width: 100%;
	display: flex;
	align-items: center;

}

.actionBtn{
	align-items: center;
  background-color: #000;
  border: none;
  border-radius: 6px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Mulish;
  font-size: 16px;
  height: 40px;
  justify-content: center;
	margin-left: 5px;
	margin-right: 5px;
  width: 100px;
}

.field{
	overflow-wrap: anywhere;
	white-space: "normal";
}

.cancelBtn {
  background-color: #fff;
  border: none;
  border-radius: 5px;
	color: #000;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  padding: 10px;
  width: 40%;
}

.confirmBtn {
  background-color: #000;
  border: none;
  border-radius: 5px;
	color: #fff;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  padding: 10px;
  width: 40%;
}