.loaderContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.mapContainer {
	height: 100%;
	width: 100%;
}

.container {
	display: flex;
	height: 100%;
	width: 100%;
	cursor: pointer;
}

.card {
	display: flex;
	align-items: center;
	gap: 2px;
	height: 100%;
	width: 100%;
	color: black;
	font-family: "Mulish";
	font-weight: 700;
	padding: 5px;
}

.runnerContainer {
	display: flex;
	height: 100%;
	width: 100%;
}

.runnerCard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2px;
	height: 100%;
	max-width: 250px;
	color: black;
	font-family: "Mulish";
	font-weight: 700;
	padding: 5px;
}

.markerShadow {
	box-shadow: 0px 0px 9px white, 0px 0px 9px white;
	border-radius: 100%;
	height: 35px;
	width: 35px;
	position: absolute;
	top: -35px;
	left: -18px;
}

.markerShadowLabel {
	box-shadow: 0px 0px 9px white, 0px 0px 9px white;
	border-radius: 100%;
	height: 35px;
	width: 35px;
	position: absolute;
	left: -18px;
	top: 32px;
}
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.btn {
	position: absolute;
	top: 20px;
	left: 20px;
  background-color: #fff;
	width: 120px;
	height: 45px;
	border: none;
	outline: none;
  border-radius: 7px;
}
