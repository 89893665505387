.collapseSection {
	align-items: center;
	background-color: #f4f4f4;
	border-radius: 10px;
	gap: 10px;
	padding: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	transition: 0.4s all;
}
.mainTitleContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	background-color: #f4f4f4;
	padding: 4px;
}
.mainTitle {
	font-weight: 800;
}
.mainBodyContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}

.mainContainer {
	max-width: 1200px;
	margin: auto;
	font-family: "Mulish";
	width: 100%;
	padding: 0 20px 20px;
}

.clipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}

.headerContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 15px;
	margin-bottom: 15px;
}
.titleContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.pageTitle {
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
}
.container {
	width: 100%;
	display: grid;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;
	grid-column-gap: 25px;
	margin-bottom: 10px;
}
.contentContainer {
	display: flex;
	flex-direction: column;
	background-color: #ffff;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	border-radius: 5px;
	height: 100%;
}

.bodyContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}
.bodyTitle {
	font-weight: 700;
}