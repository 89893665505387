.clipLoader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mainContainer {
	/* width: 100%; */
	/* justify-content: space-between; */
	align-items: center;
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
	/* height: 50px; */
	position: relative;
}

.dropDownContainer {
	display: flex;
	flex-direction: column;
}
.dropDownBtn {
	border: 0;
	border-radius: 5px;
	background: #f4f4f4;
	width: 180px;
	padding: 6px 8px;
	text-align: center;
	font-family: "Mulish";
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;
	position: relative;
	margin-right: 5px;
	/* height: 100%; */
}
.btnContent {
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-overflow: ellipsis;
}
.selected {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 15px;
}

.ulContainer {
	background-color: #fff;
	border: 1px solid #f4f4f4;
	display: flex;
	flex-direction: column;
	max-width: 180px;
	width: 180px;
	padding: 0;
	max-height: 200px;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	top: 100%;
	list-style-type: none;
	left: 0;
}

.option {
	text-overflow: ellipsis;
	border-radius: 6px;
	padding: 10px;
	font-size: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}
.option:hover {
	background-color: #f4f4f4;
	border-radius: 10px;
	cursor: pointer;
}

.selectedOption {
	text-overflow: ellipsis;
	background-color: #f4f4f4;
	border-radius: 6px;
	padding: 10px;
	font-size: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.mainContainerNoData {
	width: 100%;
	justify-content: space-between;
	/* align-items: center; */
	display: flex;
	pointer-events: none;
	margin-top: 10px;
	margin-bottom: 10px;
}

.btnContainer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	border-bottom: 0.2px solid #4444;
	padding: 5px;
	position: sticky;
    top: 0;
    right: 0;
	background-color: #fff;
}

.clearBtn {
	align-items: center;
	border: none;
	cursor: pointer;
	display: flex;
	font-family: Mulish;
	font-size: 14px;
	justify-content: center;
    background-color: #fff;
    background-color: #000;
	border: 0.2px solid #000;
	border-radius: 6px;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
	width: 30%;
    min-width: fit-content;
}
