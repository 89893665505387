.sidebar {
	box-sizing: content-box;
	height: 90%;
	width: 20%;
	max-width: 315px;
	position: absolute;
	right: 20px;
	top: 20px;
	background-color: white;
	border-radius: 8px;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 10px;
}

.sidebar::-webkit-scrollbar {
	display: none;
}

.container {
	position: relative;
	height: 100%;
	width: 100%;
}

.appBar {
	position: sticky;
	top: -10px;
	margin: -10px 0px 10px -10px;
	width: calc(100% + 20px);
	height: 60px;
	min-height: 60px;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background-color: #dbdbdb;
	z-index: 5;
}

.appBarContainer {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.appBarTitle {
	font-family: "Mulish";
	font-weight: 700;
	font-size: 24px;
	color: black;
	padding-left: 10px;
}

.appBarIcon {
	padding-right: 10px;
}

.buttonStyle {
	background-color: white;
	border-radius: 100%;
}

.filterContainer {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.filterBarContainer {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.filterBarIcon {
	padding-left: 10px;
}

.filterBarTitle {
	font-family: "Mulish";
	font-weight: 700;
	font-size: 24px;
	color: black;
	padding-right: 20px;
}

.taskTypesContainer {
	position: relative;
	height: 100%;
	width: 100%;
}

.taskTypesHeader {
	font-family: "Mulish";
	font-weight: 700;
	font-size: 24px;
	color: black;
	padding: 10px;
}
.checkBoxContainer {
	height: 100%;
	width: fit-content;
	padding: 10 10 0 20;
}

.filterBarBottom {
	position: sticky;
	bottom: -10px;
	margin: 0px 0px -10px -10px;
	width: calc(100% + 20px);
	height: 60px;
	min-height: 60px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: #dbdbdb;
	cursor: pointer;
	z-index: 5;
}

.filterBarBottom:hover {
	background-color: rgb(188, 188, 188);
	transform: scale(1.05);
}

.filterBottomContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.saveButtonText {
	font-family: "Mulish";
	font-weight: 700;
	font-size: 24px;
	color: black;
}

.tasksContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding-bottom: 10px;
}

.card {
	box-sizing: content-box;
	width: 95%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	background-color: #f4f4f4;
	border-radius: 8px;
	cursor: pointer;
	min-height: fit-content;
	gap: 8px;
	box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
}

.selectedCard {
	box-sizing: content-box;
	width: 95%;
	padding: 10px;
	display: flex;
	flex-direction: column;
	background-color: #f4f4f4;
	border-radius: 8px;
	cursor: pointer;
	min-height: fit-content;
	gap: 8px;
	box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
	border: 2px solid green;
}

.card:hover {
	background-color: rgb(188, 188, 188);
	transform: scale(1.03);
}

.card:active {
	background-color: green;
	opacity: 0.5;
}

.selectedCard:hover {
	background-color: rgb(188, 188, 188);
	transform: scale(1.03);
}

.selectedCard:active {
	background-color: green;
	opacity: 0.5;
}

.selectedDivider {
	width: calc(100% + 20px);
	margin-left: -10px;
	border: 1px solid black;
}
.assignMessage {
	font-family: "Mulish";
	font-size: 14px;
	font-weight: 400;
	color: black;
	margin-top: -5px;
}
.itemHeader {
	font-family: "Mulish";
	font-size: 16px;
	font-weight: 600;
	flex-shrink: 1;
	color: black;
	display: flex;
	align-items: center;
}

.itemText {
	font-family: "Mulish";
	font-size: 14px;
	font-weight: 400;
	flex-shrink: 1;
	color: black;
}

.loaderContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.taskLoaderContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: calc(100% - 60px);
}

.assignHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: "Mulish";
	font-weight: 600;
	font-size: 16px;
	margin-top: -5px;
}

.assigned {
	color: green;
	display: flex;
	align-items: center;
}

.unassigned {
	color: red;
}

.vendor {
	font-size: 13px;
	color: #fff;
}

.link {
	color: #00008b;
	text-decoration: none;
    width: fit-content;
}
.link:hover {
	color: #00008b;
	text-decoration: none;
}


