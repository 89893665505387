.mainContainer {
	max-width: 1200px;
	margin: auto;
	font-family: "Mulish";
	width: 100%;
	padding: 0 20px 20px;
}

.headerContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 15px;
	margin-bottom: 15px;
}

.dropDownContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding-top: 15px;
	margin-bottom: 15px;
	/* position: relative; */
}

.dropDownContentContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
}

.dropDownBtn {
	border: 0;
	border-radius: 5px;
	background: #f4f4f4;
	width: 180px;
	padding: 6px 8px;
	text-align: center;
	font-family: "Mulish";
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;
	position: relative;
}

.btnContent {
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 7px;
	margin-left: 15px;
}

.ulContainer {
	background-color: #fff;
	border: 1px solid #f4f4f4;
	display: flex;
	flex-direction: column;
	min-width: 180px;
	z-index: 100;
	padding: 0;
	position: absolute;
	top: 100%;
	left: 0px;
}

.entry {
	padding: 10px; 
	cursor: pointer;
}

.childEntries .entry:hover {
	background-color: #f4f4f4;
	border-radius: 10px;
}

.selected {
	background-color: #f4f4f4 !important;
}

.childEntries .entry {
	background-color: transparent;
	transition: background-color 0.3s;
	padding-left: 35px;
	border-radius: 10px;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.pageTitle {
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
}

.clipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}
