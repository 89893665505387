.collapseSection {
	align-items: center;
	background-color: #f4f4f4;
	border-radius: 10px;
	gap: 10px;
	padding: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	transition: 0.4s all;
}

.mainTitleContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	background-color: #f4f4f4;
	padding: 4px;
}
.mainTitle {
	font-weight: 800;
}

.mainBodyContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}
.container {
	width: 100%;
	display: grid;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;
	grid-column-gap: 25px;
	margin-bottom: 10px;
}

.mainContainer {
	max-width: 1200px;
	margin: auto;
	font-family: "Mulish";
	width: 100%;
	padding: 0 20px 20px;
}

.clipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}
.btnClipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 100vh;
	width: 100%; */
}

.headerContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 15px;
	margin-bottom: 15px;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.pageTitle {
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
}

.bodyTitle {
	font-weight: 700;
}

.bodyContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}

.contentContainer {
	display: flex;
	flex-direction: column;
	background-color: #ffff;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	border-radius: 5px;
	height: 100%;
}

.editableField {
	display: flex;
	align-items: center;
}
.editField {
	display: flex;
	align-items: center;
}

.input {
	background-color: #f4f4f4;
	border: none;
	border-radius: 5px;
	font-family: Mulish;
	height: 40px;
	width: 100%;
	padding: 5px;
}

.actionBtnsContainer{
	width: 100%;
	display: flex;
	align-items: center;

}

.actionBtn{
	align-items: center;
    background-color: #000;
    border: none;
    border-radius: 6px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: Mulish;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    width: 100px;
	margin-left: 5px;
	margin-right: 5px;
}

.field{
	overflow-wrap: anywhere;
	white-space: "normal";
}

.cancelBtn{
    background-color: #fff;
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    padding: 10px;
    width: 40%;
	color: #000;

  }
.confirmBtn{
    background-color: #000;
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    padding: 10px;
    width: 40%;
	color: #fff;

  }