body{
    margin:0
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content:center;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    align-items:center;
    height:100%;
    width:100%;
}   

.loaderContainer{
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    width:100%;
}

.grunner_input_field {
    padding-top: 1em;
    width: 460px;
}

.title {
    font-family: "Mulish";
    font-size: 2em;
}

.innerContainer {
    padding: 12em 3em 18em 3em;
    height: fit-content;
    width: fit-content;
}

.sign_in_button {
    z-index: 1;
    color: white;
    background-color: #2b08a0;
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    font-size: 20px;
    flex-shrink:0px;
    width: 550px;
    height: 55px;
    cursor: pointer;
}
.sign_in_button:focus {
     outline: 5px auto -webkit-focus-ring-color;
}