.gm-style .gm-style-iw-d::-webkit-scrollbar-track, 
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece
/* .gm-style-iw-d::-webkit-scrollbar, */
/*.gm-style .gm-style-iw-tc::after */ { 
    visibility: hidden;
    /* display: none; */
    /* position:absolute; */
  }
.gm-style-iw-chr {
  display: none;
}

.gm-style-iw.gm-style-iw-c {
  max-height: fit-content !important;
  max-width: fit-content !important;
  /* border-radius: 8px; */
  background-color: none;
  padding: 0px !important;
  overflow:visible;
  font-family: 'Mulish';
  color: white;
}

.gm-ui-hover-effect {
  visibility: hidden;
  display: none;
  filter: invert(1);
}

.gm-style-iw-d{
  height:100%;
  width:100%;
  box-sizing: content-box !important;
  overflow: hidden !important;
}

  /* .gm-style .gm-style-iw-d {
    background-color: white;
  } */