.sidebar {
    box-sizing: content-box;
    background-color: white;
    height: 90%;
    width: 20%;
    max-width: 315px;
    padding: 10px;
    position:absolute;
    top:20px;
    left:20px;
    border-radius: 8px; 
    overflow-x: hidden;
    overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
    display: none;
}
.container {
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
}

.appBar {
    position:sticky;
    top: -10px;
    margin: -10px 0px 0px 0px;
    width: calc(100% + 20px);
    height: 60px;
    min-height: 60px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color:#dbdbdb;
    z-index:5;
}

.appBarContainer {
    height: 100%;
    width:100%;
    display: flex;
    align-items:center;
    justify-content: space-between;
}

.appBarTitle {
    font-family: 'Mulish';
    font-weight:700;
    font-size: 24px;
    color: black;
    padding-left:10px;
}

.appBarIcon {
    padding-right: 10px;
}

.buttonStyle{
    background-color: white;
    border-radius: 100%;
}

.historyBar {
    position:sticky;
    top: -10px;
    margin: -10px 0px 0px -10px;
    width: calc(100% + 20px);
    height: 60px;
    min-height: 60px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color:#dbdbdb;
    z-index:5;
}

.historyContainer {
    position:relative;
    height:100%;
    width: 100%;

}

.historyContainer::-webkit-scrollbar {
    display: none;
}

.historyBarContainer {
    height: 100%;
    width:100%;
    display: flex;
    align-items:center;
    justify-content: space-between;
}

.historyBarIcon {
    padding-left: 10px;
}

.historyBarTitle {
    font-family: 'Mulish';
    font-weight:700;
    font-size: 24px;
    color: black;
    padding-right:20px;
}

.card {
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    max-height: fit-content;
    border-radius: 8px;
    background-color: #f4f4f4;
    padding: 10px;
    width:95%;
    overflow-x:clip;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0px rgb(0 0 0 / 2%);
}

.itemHeader {
    font-family: 'Mulish';
    font-size: 16px;
    font-weight:600;
    color: black;
}

.itemText {
    font-family:'Mulish';
    font-size:14px;
    font-weight:400;
    color: black;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.divider {
    display: block;
    width: 100%;
}

.taskWindow {
    height:100%;
    width:100%;
}

.tasksContainer {
    display: flex;
    flex-direction: column;
    align-items:center;
    height:fit-content;
    gap: 10px;
    padding-top:10px;
    padding-bottom:10px;
}

.area {
    position:relative;
    height:100%;
    width:100%;

}
.area:hover > .tasksOverlay{
    background-color: lightgreen;
    opacity: 0.5;
 }
 .tasksOverlay {
    height:calc(100% + 10px);
    width:calc(100% + 20px);
    margin-left:-10px;
    border-radius:8px;
    position:absolute;
    left:0px;
    z-index:4;
    cursor:pointer;

}
.taskHeader {
    display: block;
    width: 100%;
    padding: 5px 5px -5px 5px;
    font-family: 'Mulish';
    font-weight:700;
    font-size: 18px;
    color: black;
}
.cancelContainer {
    display: grid;
    grid-template-columns: [first] auto [second] 35px;
}

.iconButton{
    position: relative;
    top: -7px;
    right:-10px;
 }

 .buttonStyle{
    background-color: white;
    border-radius: 100%;
    margin-right: 5px;
 }

 .viewImage {
    display: flex;
    align-items: center;
 }

 .orderContainer {
    flex-shrink: 2;
 }

 .itemsContainer {
    display: flex;
    flex-direction: column;
 }
.loaderContainer{
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}

.taskLoaderContainer {
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    padding-top: 75px;
}

.historyLoaderContainer {
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    padding-top: calc(100% - 60px);
}

.cardOverride {
    cursor: default;
}
.cardOverride:hover{
    background-color: #f4f4f4 !important;
    transform: unset !important;
}

.cardOverride:active {
    background-color: #f4f4f4 !important;
}