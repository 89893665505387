.entry {
	padding: 10px; 
	cursor: pointer;
}
.childEntries .entry:hover {
	background-color: #f4f4f4;
	border-radius: 10px;
}

.selected {
	background-color: #f4f4f4 !important;
}
.dropDownContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-top: 15px;
	margin-bottom: 15px;
	position: relative;
}

.dropDownContentContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
}

.dropDownBtn {
	border: 0;
	border-radius: 5px;
	background: #f4f4f4;
	width: 180px;
	padding: 6px 8px;
	text-align: center;
	font-family: "Mulish";
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;
	position: relative;
}
.btnContent {
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 7px;
	margin-left: 15px;
}

.ulContainer {
	background-color: #fff;
	border: 1px solid #f4f4f4;
	display: flex;
	flex-direction: column;
	min-width: 180px;
	z-index: 100;
	padding: 0;
	position: absolute;
	top: 100%;
	left: 0px;
}

.entry:hover {
	background-color: #f4f4f4;
	border-radius: 10px;
}


.clipLoader{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}