.mainTitleContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	background-color: #f4f4f4;
	padding: 4px;
}

.collapseSection {
	align-items: center;
	background-color: #f4f4f4;
	border-radius: 10px;
	gap: 10px;
	padding: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	transition: 0.4s all;
}

.mainTitle {
	font-weight: 800;
}
.mainBodyContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}

.mainContainer {
	max-width: 1200px;
	margin: auto;
	font-family: "Mulish";
	width: 100%;
	padding: 0 20px 20px;
}

.clipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}

.headerContainerOrders {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 15px;
	margin-bottom: 15px;
}

.navContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.headerContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 15px;
	margin-bottom: 15px;
}

.container {
	width: 100%;
	display: grid;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;
	grid-column-gap: 25px;
	margin-bottom: 10px;
}
.noDataContainer {
	width: 100%;
	display: grid;
	display: grid;
	width: 100%;
	margin-bottom: 10px;
}

.contentContainer {
	display: flex;
	flex-direction: column;
	background-color: #ffff;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	border-radius: 5px;
	height: 100%;
}
.specialHourscontentContainer {
	display: flex;
	flex-direction: column;
	background-color: #ffff;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	border-radius: 5px;
	height: 100%;
	max-height: 350px;
	overflow-y: auto;
}
.bodyInfoContainer {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	padding: 5px;
}

.bodyTitle {
	font-weight: 700;
}

.hoursContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	background-color: #f4f4f4;
	border-radius: 5px;
	padding: 5px;
	margin: 5px 0px;
	width: 100%;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.pageTitle {
	font-size: 25px;
	margin-left: 5px;
	margin-right: 5px;
}
.title {
	font-size: 14px;
	margin-left: 5px;
	margin-right: 5px;
}

.bodyContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}

.productsContainer {
	border-style: none;
	border-bottom-style: inset;
	border-color: rgb(195, 193, 193);
	border-width: 3px;
	width: 100%;
}

.btnContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.actionBtn {
	align-items: center;
	background-color: #000;
	border: none;
	border-radius: 6px;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
	cursor: pointer;
	display: flex;
	font-family: Mulish;
	font-size: 16px;
	height: 40px;
	justify-content: center;
	/* width: 100px; */
	margin-left: 5px;
	margin-right: 5px;
}
.actionBtnLevel {
	align-items: center;
	background-color: #000;
	border: none;
	border-radius: 6px;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
	cursor: pointer;
	display: flex;
	font-family: Mulish;
	font-size: 16px;
	height: 40px;
	justify-content: center;
	/* width: 100px; */
	margin-left: 5px;
	margin-right: 5px;
	width: 20%;
}
.cancelBtn {
	background-color: #fff;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	padding: 10px;
	width: 40%;
	color: #000;
}
.cancelBtnLevel {
	background-color: #fff;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	padding: 10px;
	width: 20%;
	color: #000;
}
.confirmBtn {
	background-color: #000;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	padding: 10px;
	width: 40%;
	color: #fff;
}

.customCheckmark {
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 10px 0px;
	padding: 10px 2px;
}

.label {
	font-weight: 600;
	margin: 0px 10px;
}

.customCheckmark .checkmark {
	min-width: 20px;
	min-height: 20px;
	max-width: 20px;
	max-height: 20px;
	border: 1px solid black;
	border-radius: 8px;
	display: inline-block;
	background-color: white;
	background: #222
		url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png)
		center/1250% no-repeat;
}

.customCheckmark input:checked + .checkmark {
	background-size: 60%;
}

.customCheckmark input {
	display: none;
}

.checkBoxContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: #fff;
	border: 1px solid #f4f4f4;


}

.selectContainer {
	padding: 15px 0;
	width: 100%;
}


.input {
	border: none;
	outline: none;

	background-color: #f4f4f4;
	padding: 10px;
}


.dropDownContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 15px;
	/* margin-bottom: 15px; */
	width: 100%;
	align-items: center;
}

.dropDownContentContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}

.dropDownBtn {
	border: 0;
	border-radius: 5px;
	background: #f4f4f4;
	width: 100%;

	padding: 6px 8px;
	text-align: center;
	font-family: "Mulish";
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;
	position: relative;
}


.btnContent {
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 7px;
	margin-left: 15px;
}

.ulContainer {
	background-color: #fff;
	border: 1px solid #f4f4f4;
	display: flex;
	flex-direction: column;
	min-width: 180px;
	padding: 0;
	width: 100%;
	
}

.option{
	padding: 10px 2px;
}
.option:hover {
	background-color: #f4f4f4;
	border-radius: 10px;
	width: 100%;
	cursor: pointer;
}
